import React from 'react';

import SEO from '~/components/seo';
import PrivacyPolicy from '~/components/PrivacyPolicy';

const Privacy = props => (
  <>
    <SEO title="PrivacyPolicy.title" pageContext={props.pageContext} />
    <PrivacyPolicy />
  </>
);

export default Privacy;
