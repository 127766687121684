import React from 'react';
import Hero from '~/components/Hero/index.tsx';
import { Section, P } from '~/components/BaseElements';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

const Privacy = () => {
  const { t } = useTranslation();
  return (
    <Section py={0}>
      <Hero isSubPage>{t('PrivacyPolicy.title')}</Hero>
      <Box py={8}>
        <Container>
          <Grid item md={8}>
            <P>{t('PrivacyPolicy.p1')}</P>
            <P>{t('PrivacyPolicy.p2')}</P>
            <P>{t('PrivacyPolicy.p3')}</P>
            <P>{t('PrivacyPolicy.p4')}</P>
          </Grid>
        </Container>
      </Box>
    </Section>
  );
};

export default Privacy;
